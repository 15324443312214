import React from "react";
import { animateWithGsap } from "../utils/animations.js";
import { useGSAP } from "@gsap/react";

const Contact = () => {
  useGSAP(() => {
    animateWithGsap(".contact-button", {
      opacity: 1,
      duration: 0.5, // Adjust duration as needed
      ease: "power2.out", // Use an elastic-like easing function
    });
  }, []);

  return (
    <div className="contact-page-wrapper" id="contact">
      <div className="contact-bg">
        <div className="content-center">
          <h1>Ready to Transform Your School Experience?</h1>
          <h3>
            Unlock a world of seamless educational management, collaborative{" "}
            <br /> learning, and transparent communication.
          </h3>
          <div className="button-wrapper">
            <button className="contact-button" href="/">
              Join Today
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
