/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import { toast, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import bg from "../assets/img/bg.png";
import logo2 from "../../src/assets/img/Schoolfy-white.png";
import logo3 from "../../src/assets/img/Schoolfy-black.png";
import { ChevronRightIcon } from "@heroicons/react/solid";
import Teacher from "../assets/img/TeacherAccount.svg"
import TeacherHovered from "../assets/img/TeacherAccountHovered.svg"
import ParentHovered from "../assets/img/ParentAccountHovered.svg"
import Parent from "../assets/img/ParentAccount.svg"
import Close from "../assets/img/close.svg"
import Instructor from "../assets/img/instructor.svg"
import InstructorHovered from "../assets/img/instructorHovered.svg"
import Learner from "../assets/img/learner.svg"
import LearnerHovered from "../assets/img/learnerHovered.svg"
import { loginUser } from "../queries/auth";

const initialValues = {
  email: "",
  password: "",
};

const userSchema = yup.object().shape({
  email: yup.string().email("Invalid Email").required("Required"),
  password: yup
    .string()
    .required("Required")
    .min(8, "Must be at least 8 characters")
    .max(20, "Must be at most 20 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,
      "Must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
});

const StudentLogin = () => {
  /// const [theme, colorMode] = useMode();

  const [, setData] = useState({});
  
  const [showPassword, setShowPassword] = useState(false);
  const [openOrg, setOpenOrg] = useState(false);
  const [openInd, setOpenInd] = useState(false);
  const [isHoveredTeacher, setIsHoveredTeacher] = useState(false);
  const [isHoveredParent, setIsHoveredParent] = useState(false);
  const [isHoveredLearner, setIsHoveredLearner] = useState(false);
  const [isHoveredInstructor, setIsHoveredInstructor] = useState(false);

   const openOrgModal = () => {
    setOpenOrg(true);
  };

    const closeOrgModal = () => {
    setOpenOrg(false);
  };
  
  
    const openIndModal = () => {
    setOpenInd(true);
  };


  const closeIndModal = () => {
    setOpenInd(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      try {
        const response = await loginUser("StudentLogin", values);
        const { status, token, message } = response;
        
        if (!status) {
          toast.error(message);
        } else {
          setData({});
          window.location.href = `${process.env.REACT_APP_ADMIN_URL}/authentication?token=${token}`;
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <div className="flex justify-start items-center h-screen overflow-hidden">
        <nav className="bg-none fixed w-full z-20 top-0 start-0 ">
            <div className=" flex flex-wrap items-center justify-between w-full px-10">
                <a href="/" className="lg:text-white text-[#795cff] font-semibold hover:font-bold border-2 lg:border-white border-[#795cff] px-4 py-2 hover:text-[#795cff] hover:bg-white rounded-full">Schoolfy</a>
                {/* <div class="flex items-center font-semibold gap-4 text-gray-600">
                    Login as
                    <button type="button" class="text-white font-medium rounded-full text-sm px-4 py-2 text-center bg-[#8A70FF] hover:bg-[#795cff] transition duration-300"
                    onClick={openIndModal}
                    >Individual</button>
                </div> */}
            </div>
        </nav>
      <Toaster position="top-right" toastOptions={{ duration: 2000 }} />
      <div
        className="w-full justify-center items-center max-w-screen-lg h-full bg-cover bg-center hidden lg:flex lg:justify-center lg:items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url(${bg})`,
        }}
      >
        <img
          src={logo2}
          className="relative"
          alt="Logo"
          style={{
            height: "auto",
            width: "55%",
            paddingY: "12px",
            paddingX: "20px",
          }}
        />
      </div>

      <div className="w-full max-w-screen-lg flex justify-center items-center mt-16">
        <div className="w-full max-w-md sm:m-16 m-4 flex flex-col h-screen items-center lg:justify-center justify-start">
          <div className="flex-col lg:hidden flex justify-center py-4 m-6">
            <img src={logo3} alt="Icon" className="w-48" />
          </div>
          <form onSubmit={formik.handleSubmit} className="w-full py-4 m-6">
            <div className="mb-8 text-center">
              <h2 className="text-3xl font-bold m-2 text-start">Login as <span className="text-[#7f56d9]">Student</span></h2>
              <p className="text-lg font-[500]  m-2 text-start">
                Enter your account details
              </p>
            </div>

            <div className="mb-6">
              <div className="relative">
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`w-full py-4 px-6 border border-gray-300 rounded-full focus:outline-none ${
                    formik.touched.email && formik.errors.email
                      ? "border-red-500"
                      : ""
                  } focus:outline-none focus:border-[#8A70FF]`}
                />
              </div>
              {formik.touched.email && formik.errors.email && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors.email}
                </p>
              )}
            </div>
            <div className="mb-4">
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`w-full py-4 px-6 border border-gray-300 rounded-full focus:outline-none ${
                    formik.touched.password && formik.errors.password
                      ? "border-red-500"
                      : ""
                  } focus:outline-none focus:border-[#8A70FF]`}
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <IoMdEye className="text-[24px] text-[#5a5a5a] " />
                  ) : (
                    <IoMdEyeOff className="text-[24px] text-[#9d9d9d] hover:text-[#5a5a5a] transition duration-300" />
                  )}
                </div>
              </div>
              {formik.touched.password && formik.errors.password && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors.password}
                </p>
              )}
            </div>
            <div className="mb-8">
              <Link
                to="/forgot-password"
                className="mx-2 font-[600] text-[#8A70FF] hover:text-[#795cff]"
              >
                Forgot password?
              </Link>
            </div>
            <button
              type="submit"
              className="w-full py-4 rounded-full bg-[#8A70FF] text-white font-medium hover:bg-[#795cff] transition duration-300"
            >
              Login
            </button>
            <div className="flex justify-center items-center mt-6 gap-2 sm:gap-6">
              <a
                className="mx-2 font-[600] text-[#8A70FF] hover:text-[#795cff] cursor-pointer"
                // href="/register"
                onClick={() => openOrgModal()}
              >
                Change account
              </a>
                {/* <div className="min-h-[6px] min-w-[6px] bg-[#795cff] rounded-full whitespace-nowrap"></div> */}
           
              {/* <Link 
                to="/learner-register"
                className="mx-2 font-[600] text-[#8A70FF] hover:text-[#795cff] cursor-pointer"
                href="/register"
              >
                Create account
              </Link> */}
            </div>
          </form>
        </div>
      </div>

      {openOrg && (
        <div className="fixed inset-0 backdrop-filter backdrop-blur-sm flex items-center justify-center z-50 ">
          <div className="absolute inset-0 bg-gray-900 opacity-75 "></div>
          <div className="bg-white p-8 rounded-[20px] shadow-lg z-10 max-w-[400px] w-full m-8">
            <div className="flex flex-col mb-4">
              <div className="flex justify-between items-center ">
              <h2 className="text-[24px] text-[#101828] font-black">Choose Account</h2>
              <button onClick={closeOrgModal}>
                <img src={Close} alt="exit" className="h-5 w-5" />
              </button>
            </div>
             <p>Sign in your organization account</p>
            </div>
            
            
            <div className="flex flex-col gap-4">
              {/* <Link
              to ="/student-login"
            className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
            onMouseEnter={() => setIsHoveredStudent(true)}
            onMouseLeave={() => setIsHoveredStudent(false)}
            >
            <div className="flex justify-center items-center gap-4">
                <img
                    // src={isHovered ? StudentHovered : Student}
                    src={isHoveredStudent ? StudentHovered : Student}
                    alt="student hat"
                    className="w-8"
                />
                <h1 className={`font-bold ${isHoveredStudent ? 'text-[#7f56d9]' : 'text-[#646464]'}`}>
                    I'm a Student
                </h1>
            </div>

            <ChevronRightIcon
                className={`-mr-1 ml-2 h-5 w-5 ${isHoveredStudent ? 'text-[#7f56d9]' : 'text-[#646464]'}`}
                aria-hidden="true"
            />
        </Link> */}

              <Link
              to ="/teacher-login"
            className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
            onMouseEnter={() => setIsHoveredTeacher(true)}
            onMouseLeave={() => setIsHoveredTeacher(false)}
            >
              
               <div className="flex justify-center items-center gap-4">
                <img 
                src={isHoveredTeacher ? TeacherHovered : Teacher} 
                alt="student hat" 
                className="w-8" />
                <h1 className={`font-bold ${isHoveredTeacher ? 'text-[#7f56d9]' : 'text-[#646464]'}`}>
                I'm a Teacher
              </h1>
              </div>
              
              <ChevronRightIcon
                className={`-mr-1 ml-2 h-5 w-5 ${isHoveredTeacher ? 'text-[#7f56d9]' : 'text-[#646464]'}`}
                aria-hidden="true"
            />
              </Link>
              <Link
              to ="/parent-login"
            className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
            onMouseEnter={() => setIsHoveredParent(true)}
            onMouseLeave={() => setIsHoveredParent(false)}
            >
              
               <div className="flex justify-center items-center gap-4">
                <img 
                src={isHoveredParent ? ParentHovered : Parent} 
                alt="student hat" 
                className="w-8" />
                <h1 className={`font-bold ${isHoveredParent ? 'text-[#7f56d9]' : 'text-[#646464]'}`}>
                I'm a Parent
              </h1>
              </div>
              
              <ChevronRightIcon
                className={`-mr-1 ml-2 h-5 w-5 ${isHoveredParent ? 'text-[#7f56d9]' : 'text-[#646464]'}`}
                aria-hidden="true"
            />
              </Link>
            </div>
            
          </div>
        </div>
      )}

      {openInd && (
        <div className="fixed inset-0 backdrop-filter backdrop-blur-sm flex items-center justify-center z-50 ">
          <div className="absolute inset-0 bg-gray-900 opacity-75 "></div>
          <div className="bg-white p-8 rounded-[20px] shadow-lg z-10 max-w-[400px] w-full m-8">
            <div className="flex flex-col mb-4">
              <div className="flex justify-between items-center ">
              <h2 className="text-[24px] text-[#101828] font-black">Choose Account</h2>
              <button onClick={closeIndModal}>
                <img src={Close} alt="exit" className="h-5 w-5" />
              </button>
            </div>
             <p>Sign in your individual account</p>
            </div>
            
            
            <div className="flex flex-col gap-4">
              <Link
              to ="/learner-login"
            className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
            onMouseEnter={() => setIsHoveredLearner(true)}
            onMouseLeave={() => setIsHoveredLearner(false)}
            >
            <div className="flex justify-center items-center gap-4">
                <img
                    // src={isHovered ? StudentHovered : Student}
                    src={isHoveredLearner ? LearnerHovered : Learner}
                    alt="student hat"
                    className="w-8"
                />
                <h1 className={`font-bold ${isHoveredLearner ? 'text-[#7f56d9]' : 'text-[#646464]'}`}>
                    I'm a Learner
                </h1>
            </div>

            <ChevronRightIcon
                className={`-mr-1 ml-2 h-5 w-5 ${isHoveredLearner ? 'text-[#7f56d9]' : 'text-[#646464]'}`}
                aria-hidden="true"
            />
        </Link>

              <Link
              to ="/instructor-login"
            className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
            onMouseEnter={() => setIsHoveredInstructor(true)}
            onMouseLeave={() => setIsHoveredInstructor(false)}
            >
              
               <div className="flex justify-center items-center gap-4">
                <img 
                src={isHoveredInstructor ? InstructorHovered : Instructor} 
                alt="student hat" 
                className="w-8" />
                <h1 className={`font-bold ${isHoveredInstructor ? 'text-[#7f56d9]' : 'text-[#646464]'}`}>
                I'm a Instructor
              </h1>
              </div>
              
              <ChevronRightIcon
                className={`-mr-1 ml-2 h-5 w-5 ${isHoveredInstructor ? 'text-[#7f56d9]' : 'text-[#646464]'}`}
                aria-hidden="true"
            />
              </Link>
              
            </div>
            
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentLogin;
