import React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "../src/assets/css/index.css";
import Login from "../src/auth/Login";
import ForgotPassword from "../src/auth/ForgotPassword";
import Register from "../src/auth/Register";
import LandingPage from "./landingpage/utils/App";
import { AuthProvider } from "./contexts/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import StudentLogin from "./auth/StudentLogin";
import ParentLogin from "./auth/ParentLogin";
import TeacherLogin from "./auth/TeacherLogin";
import LearnerLogin from "./auth/LearnerLogin";
import InstructorLogin from "./auth/InstructorLogin";
import InstructorRegister from "./auth/InstructorRegister";
import LearnerRegister from "./auth/LearnerRegister";
import ResetPassword from "./auth/ResetPassword";
// import { Organization } from "./auth/Organization";
// import { Individual } from "./auth/Individual";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

const Root = () => {
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
            <Router>
              <Routes>                
                <Route path="/" element={<LandingPage />} />
                {/* <Route path="/Organization" element={<Organization />} />
                <Route path="/Individual" element={<Individual />} /> */}
                <Route path="/login" element={<Login />} />
                <Route path="/student-login" element={<StudentLogin />} />
                <Route path="/parent-login" element={<ParentLogin />} />
                <Route path="/teacher-login" element={<TeacherLogin />} />
                <Route path="/learner-login" element={<LearnerLogin />} />
                <Route path="/instructor-login" element={<InstructorLogin />} />
                <Route path="/register" element={<Register />} />
                <Route path="/instructor-register" element={<InstructorRegister />} />
                <Route path="/learner-register" element={<LearnerRegister />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
              </Routes>
            </Router>
        </AuthProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<Root />);
