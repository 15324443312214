import { privateRequest } from "../config/axios.config"

export const registerLearner = async (learner) => {
    try {
        const response = await privateRequest.post('/learner/create', { learner });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};
export const deleteLearner = async (learnerId) => {
    try {
        const response = await privateRequest.delete('/learner', { data: { learnerId } });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};
export const updateLearner = async (learner) => {
    try {
        const response = await privateRequest.put('/learner', { learner });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};
export const fetchLearnerById = async (learnerId) => {
    try {
        const response = await privateRequest.post('/learner/get-info', { learnerId });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};
export const fetchLearnerList = async (schoolCode) => {
    try {
        const response = await privateRequest.post('/learner/list', { schoolCode });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};