import React, { useState, useEffect } from 'react'
import { animateWithGsap } from "../utils/animations";
import { useGSAP } from "@gsap/react";
import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/solid";
import gsap from "gsap";
import BannerBackground from "../../assets/img/bgimage.png";
import stream from "../../assets/img/stream.png";
import book from "../../assets/img/book.png";
import chart from "../../assets/img/chart.png";
import heropic from "../../assets/img/heropic.png";
import box1 from "../../assets/img/box1.png";
import box2 from "../../assets/img/box2.png";
import box3 from "../../assets/img/box3.png";
import Navbar from "./Navbar";
import Close from "../../assets/img/close.svg";
import Learner from "../../assets/img/learner.svg";
import LearnerHovered from "../../assets/img/learnerHovered.svg"
import Instructor from "../../assets/img/instructor.svg"
import InstructorHovered from "../../assets/img/instructorHovered.svg"
import Teacher from "../../assets/img/TeacherAccount.svg"
import TeacherHovered from "../../assets/img/TeacherAccountHovered.svg"
import Student from "../../assets/img/StudentAccount.svg"
import StudentHovered from "../../assets/img/StudentAccountHovered.svg"
import ParentHovered from "../../assets/img/ParentAccountHovered.svg"
import Parent from "../../assets/img/ParentAccount.svg"

const Home = () => {
  const [animationIndex, setAnimationIndex] = useState(0);
  const [openIndRegister, setOpenIndRegister] = useState(false);
  const [openOrgLogin, setOpenOrgLogin] = useState(false);
  const [isHoveredStudent, setIsHoveredStudent] = useState(false);
  const [isHoveredTeacher, setIsHoveredTeacher] = useState(false);
  const [isHoveredParent, setIsHoveredParent] = useState(false);
  const [isHoveredLearner, setIsHoveredLearner] = useState(false);
  const [isHoveredInstructor, setIsHoveredInstructor] = useState(false);
  
      const openIndRegModal = () => {
    setOpenIndRegister(true)
  }
    const closeIndRegModal = () => {
  setOpenIndRegister(false)
  }

  const openOrgModal = () => {
    setOpenOrgLogin(true);
  };

   const closeOrgModal = () => {
    setOpenOrgLogin(false);
  };

    useGSAP(() => {
        animateWithGsap(".logo-icon", {
        y: 0,
        opacity: 1,
        stagger: 0.2,
        repeat: -1,
        repeatDelay: 1.3,
        });
    });

    useEffect(() => {
        const animateIcons = () => {
            // An array of CSS classes for animations
            const animations = ['popUp', 'popUp', 'popUp']; // Adjust this array based on the number of images
    
            let index = 0;
            const intervalId = setInterval(() => {
                // Update the state to trigger the next animation
                setAnimationIndex(index);
    
                // Increment index for the next animation
                index++;
    
                // If index exceeds the number of animations, reset it
                if (index >= animations.length) {
                    index = 0;
                }
            }, 500); // Adjust the interval duration as needed
    
            // Clear the interval when component unmounts
            return () => clearInterval(intervalId);
        };
    
        // Trigger the animation on component mount
        animateIcons();
    }, []);
    
    useGSAP(() => {
      gsap.to("#home-heading", { opacity: 1, y: 10, delay: 0.3 });
      gsap.to("#primary-text" ,{ opacity: 1, y: -10, delay: 0.4 });
      // gsap.to("#image", { opacity: 1, y: -10, delay: 0.5 });
      gsap.to("#image", {
        opacity: 1,
        y: -2,
        duration: 2,
        ease: "power1.inOut", // Use an easing function for a smoother animation
        yoyo: true, // Reverse the animation
        repeat: -1, // Repeat infinitely
        delay: 0.5,
      });
      gsap.to("#home-button", { opacity: 1, y: -10, delay: 0.5 });
      gsap.to(".home-three-features", { opacity: 1, y: -10, delay: 0.6, stagger: 0.2 });
    });

    return (
      <div className='w-full flex justify-center'>
      <div className="home-container px-8">
        <Navbar className="z-10" />

        <div className="home-banner-container mt-8 ">
          <div className="home-bannerImage-container">
            {/* <img src={BannerBackground} alt="" /> */}
          </div>
          {/* <div className="home-bannerImage-container2">
            <img src={BannerBackground} alt="" />
          </div> */}
          <div className="home-text-section">
            <h6 className="text-[24px] text-[#101828] font-black">
              Welcome to <span className="text-[#6859FF]">Schoolfy</span>
            </h6>
            <h1 id="home-heading" className="home-heading">
              Your Advanced School and Learning Management System
            </h1>

            <p id="primary-text" className="primary-text">
              We are committed to revolutionizing education through cutting-edge
              technology that supports school administration, teachers, parents,
              and students. Our platform fosters connectivity and collaboration
              among all stakeholders, providing a seamless educational
              experience for everyone involved.
            </p>

            <div id="home-button" className="home-button"></div>

            <div className="home-three gap-4">
              <div className="home-three-features ">
                <img src={stream} alt="stream" />
                <h6>Comprehensive Courses</h6>
              </div>
              <div className="home-three-features">
                <img src={book} alt="book" />
                <h6>Interactive Learning</h6>
              </div>
              <div className="home-three-features ">
                <img src={chart} alt="chart" />
                <h6>Progress Tracking</h6>
              </div>
            </div>
          </div>
          <div id="image" className="home-image-section">
            <img src={heropic} alt="banner" className="w-3/4 mx-auto mt-4" />
            {/* <div className="absolute opacity-0 lg:opacity-100 w-1/3 h-1/2 custom-lg:h-3/4">
                    <img
                    src={box1}
                    alt=""
                    className={`absolute -top-[160%] -right-[160%] logo-icon w-36 ${
                        animationIndex >= 1 ? "animated" : ""
                    }`}
                    />
                    <img
                    src={box2}
                    alt=""
                    className={`absolute bottom-[100%] -right-[160%] logo-icon w-44 ${
                        animationIndex >= 2 ? "animated" : ""
                    }`}
                    />
                    <img
                    src={box3}
                    alt=""
                    className={`absolute -top-[100%] right-[14%] logo-icon w-44 ${
                        animationIndex >= 3 ? "animated" : ""
                    }`}
                    />
                </div> */}
          </div>
        </div>
        </div>

        {openOrgLogin && (
          <div className="fixed inset-0 backdrop-filter backdrop-blur-sm flex items-center justify-center z-50 ">
            <div className="absolute inset-0 bg-gray-900 opacity-75 "></div>
            <div className="bg-white p-8 rounded-[20px] shadow-lg z-10 max-w-[400px] w-full m-8">
              <div className="flex flex-col mb-4">
                <div className="flex justify-between items-center ">
                  <h2 className="text-[24px] text-[#101828] font-black">
                    Login to your Account
                  </h2>
                  <button onClick={closeOrgModal}>
                    <img src={Close} alt="exit" className="h-5 w-5" />
                  </button>
                </div>
                <p>Sign in your organization account</p>
              </div>

              <div className="flex flex-col gap-4">
                <Link
                  to="/student-login"
                  className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
                  onMouseEnter={() => setIsHoveredStudent(true)}
                  onMouseLeave={() => setIsHoveredStudent(false)}
                >
                  <div className="flex justify-center items-center gap-4">
                    <img
                      // src={isHovered ? StudentHovered : Student}
                      src={isHoveredStudent ? StudentHovered : Student}
                      alt="student hat"
                      className="w-8"
                    />
                    <h1
                      className={`font-bold ${
                        isHoveredStudent ? "text-[#7f56d9]" : "text-[#646464]"
                      }`}
                    >
                      I'm a Student
                    </h1>
                  </div>

                  <ChevronRightIcon
                    className={`-mr-1 ml-2 h-5 w-5 ${
                      isHoveredStudent ? "text-[#7f56d9]" : "text-[#646464]"
                    }`}
                    aria-hidden="true"
                  />
                </Link>

                <Link
                  to="/teacher-login"
                  className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
                  onMouseEnter={() => setIsHoveredTeacher(true)}
                  onMouseLeave={() => setIsHoveredTeacher(false)}
                >
                  <div className="flex justify-center items-center gap-4">
                    <img
                      src={isHoveredTeacher ? TeacherHovered : Teacher}
                      alt="student hat"
                      className="w-8"
                    />
                    <h1
                      className={`font-bold ${
                        isHoveredTeacher ? "text-[#7f56d9]" : "text-[#646464]"
                      }`}
                    >
                      I'm a Teacher
                    </h1>
                  </div>

                  <ChevronRightIcon
                    className={`-mr-1 ml-2 h-5 w-5 ${
                      isHoveredTeacher ? "text-[#7f56d9]" : "text-[#646464]"
                    }`}
                    aria-hidden="true"
                  />
                </Link>
                <Link
                  to="/parent-login"
                  className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
                  onMouseEnter={() => setIsHoveredParent(true)}
                  onMouseLeave={() => setIsHoveredParent(false)}
                >
                  <div className="flex justify-center items-center gap-4">
                    <img
                      src={isHoveredParent ? ParentHovered : Parent}
                      alt="student hat"
                      className="w-8"
                    />
                    <h1
                      className={`font-bold ${
                        isHoveredParent ? "text-[#7f56d9]" : "text-[#646464]"
                      }`}
                    >
                      I'm a Parent
                    </h1>
                  </div>

                  <ChevronRightIcon
                    className={`-mr-1 ml-2 h-5 w-5 ${
                      isHoveredParent ? "text-[#7f56d9]" : "text-[#646464]"
                    }`}
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
          </div>
        )}

        {openIndRegister && (
          <div className="fixed inset-0 backdrop-filter backdrop-blur-sm flex items-center justify-center z-50 ">
            <div className="absolute inset-0 bg-gray-900 opacity-75 "></div>
            <div className="bg-white p-8 rounded-[20px] shadow-lg z-10 max-w-[400px] w-full m-8">
              <div className="flex flex-col mb-4">
                <div className="flex justify-between items-center ">
                  <h2 className="text-[24px] text-[#101828] font-black">
                    Create Account
                  </h2>
                  <button onClick={closeIndRegModal}>
                    <img src={Close} alt="exit" className="h-5 w-5" />
                  </button>
                </div>
                <p>Sign up an individual account</p>
              </div>

              <div className="flex flex-col gap-4">
                <Link
                  to="/learner-register"
                  className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
                  onMouseEnter={() => setIsHoveredLearner(true)}
                  onMouseLeave={() => setIsHoveredLearner(false)}
                >
                  <div className="flex justify-center items-center gap-4">
                    <img
                      // src={isHovered ? StudentHovered : Student}
                      src={isHoveredLearner ? LearnerHovered : Learner}
                      alt="student hat"
                      className="w-8"
                    />
                    <h1
                      className={`font-bold ${
                        isHoveredLearner ? "text-[#7f56d9]" : "text-[#646464]"
                      }`}
                    >
                      I'm a Learner
                    </h1>
                  </div>

                  <ChevronRightIcon
                    className={`-mr-1 ml-2 h-5 w-5 ${
                      isHoveredLearner ? "text-[#7f56d9]" : "text-[#646464]"
                    }`}
                    aria-hidden="true"
                  />
                </Link>

                <Link
                  to="/instructor-register"
                  className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
                  onMouseEnter={() => setIsHoveredInstructor(true)}
                  onMouseLeave={() => setIsHoveredInstructor(false)}
                >
                  <div className="flex justify-center items-center gap-4">
                    <img
                      src={isHoveredInstructor ? InstructorHovered : Instructor}
                      alt="student hat"
                      className="w-8"
                    />
                    <h1
                      className={`font-bold ${
                        isHoveredInstructor
                          ? "text-[#7f56d9]"
                          : "text-[#646464]"
                      }`}
                    >
                      I'm a Instructor
                    </h1>
                  </div>

                  <ChevronRightIcon
                    className={`-mr-1 ml-2 h-5 w-5 ${
                      isHoveredInstructor ? "text-[#7f56d9]" : "text-[#646464]"
                    }`}
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
};

export default Home;
