import React from 'react'
import { Grid } from "@mui/material"
import Blog1 from "../../assets/img/blog1.png";
import Blog2 from "../../assets/img/blog2.png";
import Blog3 from "../../assets/img/blog3.png";


const Blogs = () => {

  const newsBlog = [
    {
      image: Blog1,
      date: "November 16, 2023",
      title: "Unraveling the Mysteries of Memory and Education",
      content: "Dive into the fascinating world of neuroscience and its profound impact on education.",
    },
    {
      image: Blog2,
      date: "September 24, 2023",
      title: "Innovation in Education: A Spotlight on Unconventional Teaching Methods",
      content: "Venture beyond traditional teaching approaches as we shine a light on innovative...",
    }
  ];

  const mainBlog = [
    {
      image: Blog3,
      date: "January 13, 2024",
      title: "The Global Classroom: Connecting Students Across Borders Through Virtual Learning",
      content: "Embark on a virtual journey through the global classroom, where geographical boundaries disappear, and students connect from different corners of the world...",
    }
  ];


  return (
    <div className='blog'>
        <h4>Our recent blogs</h4>
        <Grid container className="blog-container">
          <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 2, sm: 2, md: 1, lg: 1 }}>
            <div className='blog-news'>
              {newsBlog.map((data) => (
                <Grid container key={data.title}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <img src={data.image} alt='' />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} className='blog-text'>
                    <h6 className="blog-date">{data.date}</h6>
                    <h3 className="blog-title">{data.title}</h3>
                    <p className="blog-content">{data.content}</p>
                  </Grid>
                </Grid>
              ))}
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} order={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
            <div>
                {mainBlog.map((data) => (
                  <div key={data.title} className="row">
                    <div className="blog-main">
                      <img src={data.image} alt='' />
                    </div>
                    <div className="blog-text">
                      <h6 className="blog-date">{data.date}</h6>
                      <h3 className="blog-title">{data.title}</h3>
                      <p className="blog-content">{data.content}</p>
                    </div>
                  </div>
                ))}
              </div>
          </Grid>
        </Grid>
    </div>
  )
}

export default Blogs