import React, { useState } from 'react';
import { Box, Button, TextField, Typography, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { tokens } from '../assets/js/theme';
import { privateRequest } from '../config/axios.config';
const ResetPassword = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { token } = useParams(); // Get the token from the URL
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    try {
      const response = await privateRequest.post('/student/resetpassword', { token:token, newPassword: password });
      
      const data = await response.json();
      if (data.status) {
        setSuccess('Password successfully reset');
      } else {
        setError(data.message || 'Failed to reset password');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: colors.freeduAccent[200],
        padding: '2rem',
        borderRadius: '8px',
        boxShadow: `0px 4px 12px ${colors.freeduAccent[700]}`,
        maxWidth: '400px',
        margin: 'auto',
        marginTop: '2rem',
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: '1rem', color: colors.freeduAccent[600] }}>
        Reset Password
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {success && <Typography color="success.main">{success}</Typography>}
      <form onSubmit={handleSubmit}>
        <TextField
          label="New Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{
            '& .MuiInputBase-root': {
              backgroundColor: colors.freeduAccent[100],
            },
          }}
        />
        <TextField
          label="Confirm Password"
          type="password"
          fullWidth
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{
            '& .MuiInputBase-root': {
              backgroundColor: colors.freeduAccent[100],
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{
            marginTop: '1rem',
            backgroundColor: colors.freeduAccent[400],
            color: colors.freeduAccent[800],
            '&:hover': {
              backgroundColor: colors.freeduAccent[300],
              boxShadow: `0px 4px 12px ${colors.freeduAccent[700]}`,
            },
          }}
          fullWidth
        >
          Reset Password
        </Button>
      </form>
    </Box>
  );
};

export default ResetPassword;
