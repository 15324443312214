import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import { toast, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import bg from "../assets/img/bg.png";
import logo2 from "../../src/assets/img/Schoolfy-white.png";
import logo3 from "../../src/assets/img/Schoolfy-black.png";
import { loginUser } from "../queries/auth";

const initialValues = {
  email: "",
  password: "",
};

const userSchema = yup.object().shape({
  email: yup.string().email("Invalid Email").required("Required"),
  password: yup
    .string()
    .required("Required")
    .min(8, "Must be at least 8 characters")
    .max(20, "Must be at most 20 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,
      "Must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
});

const Login = () => {
  // const [theme, colorMode] = useMode();

  const [, setData] = useState({});
  
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: userSchema,
    onSubmit: async (values) => {
      try {
        const response = await loginUser("SchoolAdminLogin", values);
        const { status, token, message } = response;
        
        if (!status) {
          toast.error(message);
        } else {
          setData({});
          window.location.href = `${process.env.REACT_APP_ADMIN_URL}/authentication?token=${token}`;
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <div className="flex justify-start items-center h-screen overflow-hidden">
      <nav className="bg-none fixed w-full z-20 top-0 start-0 ">
        <div className=" flex flex-wrap items-center justify-between w-full px-10">
          <a
            href="/"
            className="lg:text-white text-[#795cff] font-semibold hover:font-bold border-2 lg:border-white border-[#795cff] px-4 py-2 hover:text-[#795cff] hover:bg-white rounded-full"
          >
            Schoolfy
          </a>
        </div>
      </nav>
      <Toaster position="top-right" toastOptions={{ duration: 2000 }} />
      <div
        className="w-full justify-center items-center max-w-screen-lg h-full bg-cover bg-center hidden lg:flex lg:justify-center lg:items-center"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url(${bg})`,
        }}
      >
        <img
          src={logo2}
          className="relative"
          alt="Logo"
          style={{
            height: "auto",
            width: "55%",
            paddingY: "12px",
            paddingX: "20px",
          }}
        />
      </div>

      <div className="w-full max-w-screen-lg flex justify-center items-center ">
        <div className="w-full max-w-md m-16 flex flex-col h-screen items-center lg:justify-center justify-start">
          <div className="flex-col lg:hidden flex justify-center py-4 m-6">
            <img src={logo3} alt="Icon" className="w-48" />
          </div>
          <form onSubmit={formik.handleSubmit} className="w-full py-4 m-6">
            <div className="mb-8 text-center">
              <h2 className="text-3xl font-bold m-2 text-start">
                Login as <span className="text-[#7f56d9]">Admin</span>
              </h2>
              <p className="text-lg font-[500]  m-2 text-start">
                Enter your account details
              </p>
            </div>

            <div className="mb-6">
              <div className="relative">
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`w-full py-4 px-6 border border-gray-300 rounded-full focus:outline-none ${
                    formik.touched.email && formik.errors.email
                      ? "border-red-500"
                      : ""
                  } focus:outline-none focus:border-[#8A70FF]`}
                />
              </div>
              {formik.touched.email && formik.errors.email && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors.email}
                </p>
              )}
            </div>
            <div className="mb-4">
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`w-full py-4 px-6 border border-gray-300 rounded-full focus:outline-none ${
                    formik.touched.password && formik.errors.password
                      ? "border-red-500"
                      : ""
                  } focus:outline-none focus:border-[#8A70FF]`}
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <IoMdEye className="text-[24px] text-[#5a5a5a] " />
                  ) : (
                    <IoMdEyeOff className="text-[24px] text-[#9d9d9d] hover:text-[#5a5a5a] transition duration-300" />
                  )}
                </div>
              </div>
              {formik.touched.password && formik.errors.password && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors.password}
                </p>
              )}
            </div>
            <div className="mb-8">
              <Link
                to="/forgot-password"
                className="mx-2 font-[600] text-[#8A70FF] hover:text-[#795cff]"
              >
                Forgot password?
              </Link>
            </div>
            <button
              type="submit"
              className="w-full py-4 rounded-full bg-[#8A70FF] text-white font-medium hover:bg-[#795cff] transition duration-300"
            >
              Login
            </button>
            {/* <div className="flex w-full justify-center mt-6">
              <h1 className="flex items-center">Don't have an account?</h1>
              <a
                className="mx-2 font-[600] text-[#8A70FF] hover:text-[#795cff]"
                href="/register"
              >
                Sign up
              </a>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
