import { privateRequest } from "../config/axios.config"

export const registerUser = async (user) => {
    try {
        const response = await privateRequest.post('/user/create', { user });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};
export const loginUser = async (loginType, user) => {
    try {
        const response = await privateRequest.post(`/auth/${loginType}`, { ...user });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};
export const deleteUser = async (userId) => {
    try {
        const response = await privateRequest.delete('/user', { data: { userId } });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};
export const updateUser = async (user) => {
    try {
        const response = await privateRequest.put('/user', { user });
        return response.data;
    } catch (error) {
        console.error(error);
    }
};
export const fetchUserById = async (userId) => {
    try {
        const response = await privateRequest.post('/user/get-info', { userId });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};
export const fetchUserList = async (schoolCode) => {
    try {
        const response = await privateRequest.post('/user/list', { schoolCode });
        return response.data.data;
    } catch (error) {
        console.error(error);
    }
};