import { useState } from "react";
import { useMode } from "../../src/assets/js/theme";
import { React } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import bg from "../assets/img/bg.png";
import logo2 from "../../src/assets/img/Schoolfy-white.png";
import logo3 from "../../src/assets/img/Schoolfy-black.png";
import * as yup from "yup";
import axios from "axios";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
};

const userSchema = yup.object().shape({
  firstName: yup.string().required("Required"),
  lastName: yup.string().required("Required"),
  email: yup.string().email("Invalid Email").required("Required"),
  password: yup
    .string()
    .required("Required")
    .min(8, "Must be at least 8 characters")
    .max(20, "Must be at most 20 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,
      "Must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
    ),
});



const InstructorRegister = () => {
  const navigate = useNavigate();
  const [theme, colorMode] = useMode();
  const [data, setData] = useState(initialValues);
  const [showPassword, setShowPassword] = useState(false);
console.log(data,colorMode);
  const handleSubmit = async (values) => {
    try {
      const { data } = await axios.post("/auth/SchoolAdminRegister", values);

      if (data.error) {
        toast.error(data.error);
      } else {
        setData(initialValues);
        toast.success("Registered successfully");
        navigate("/login");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
        <div className="flex justify-start items-center h-screen overflow-hidden">
            <nav className="bg-none fixed w-full z-20 top-0 start-0 ">
            <div className=" flex flex-wrap items-center justify-between w-full px-10">
                <a href="/" className="lg:text-white text-[#795cff] font-semibold hover:font-bold border-2 lg:border-white border-[#795cff] px-4 py-2 hover:text-[#795cff] hover:bg-white rounded-full">Schoolfy</a>
                <div class="flex items-center font-semibold gap-4 text-gray-600">
                    Signup as
                    <Link to="/learner-register" 
                    class="text-white font-medium rounded-full text-sm px-4 py-2 text-center bg-[#8A70FF] hover:bg-[#795cff] transition duration-300">
                        Learner
                    </Link>
         
                </div>
            </div>
        </nav>
          <div
            className="w-full justify-center items-center max-w-screen-lg h-full bg-cover bg-center hidden lg:flex lg:justify-center lg:items-center"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url(${bg})`,
            }}
          >
            <img
              src={logo2}
              className="relative"
              alt="Logo"
              style={{
                height: "auto",
                width: "55%",
                paddingY: "12px",
                paddingX: "20px",
              }}
            />
          </div>

          <div className="w-full max-w-screen-lg flex justify-center items-center ">
            <div className="w-full max-w-md m-16 flex flex-col h-screen items-center lg:justify-center justify-start">
              <div className="flex-col lg:hidden flex justify-center py-4 m-6">
                <img src={logo3} alt="Icon" className="w-48" />
              </div>

              <Formik
                initialValues={initialValues}
                validationSchema={userSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form className="w-full py-4 m-6">
                    <div className="mb-8 text-center">
                      <h2 className="text-3xl font-bold m-2 text-start">
                        Create a <span className="text-[#795cff]">Instructor's</span> Account
                      </h2>
                      <p className="text-lg font-[500]  m-2 text-start">
                        Sign up and inspire others
                      </p>
                    </div>

                    <div className="mb-6">
                      <div className="relative">
                        <Field
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          className={`w-full py-4 px-6 border border-gray-300 rounded-full focus:outline-none ${
                            theme.palette.mode === "dark"
                              ? "text-white"
                              : "text-gray-800"
                          } bg-white border border-gray-300 rounded-full px-4 py-2 focus:outline-none focus:border-[#8A70FF]`}
                        />
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>

                    <div className="grid gap-4 mb-6">
                      <Field
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        className={`w-full py-4 px-6 border border-gray-300 rounded-full focus:outline-none${
                          theme.palette.mode === "dark"
                            ? "text-white"
                            : "text-gray-800"
                        } bg-white border border-gray-300 rounded-full px-4 py-2 focus:outline-none focus:border-[#8A70FF]`}
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="text-red-500"
                      />
                    </div>

                    <div className="mb-6">
                      <div className="relative">
                        <Field
                          type="email"
                          name="email"
                          placeholder="Email"
                          className={` w-full py-4 px-6 border border-gray-300 rounded-full focus:outline-none${
                            theme.palette.mode === "dark"
                              ? "text-white"
                              : "text-gray-800"
                          } bg-white border border-gray-300 rounded-full px-4 py-2 focus:outline-none focus:border-[#8A70FF]`}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="relative">
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          className={`w-full py-4 px-6 border border-gray-300 rounded-full focus:outline-none ${
                            theme.palette.mode === "dark"
                              ? "text-white"
                              : "text-gray-800"
                          } bg-white border border-gray-300 rounded-full px-4 py-2 focus:outline-none focus:border-[#8A70FF]`}
                        />
                        <div
                          className="absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <IoMdEye className="text-[24px] text-[#5a5a5a] " />
                          ) : (
                            <IoMdEyeOff className="text-[24px] text-[#9d9d9d] hover:text-[#5a5a5a] transition duration-300" />
                          )}
                        </div>

                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                    </div>

                    <div className="grid gap-4">
                      <button
                        type="submit"
                        className="w-full py-4 rounded-full bg-[#8A70FF] text-white font-medium hover:bg-[#795cff] transition duration-300"
                      >
                        Register
                      </button>
                    </div>
                    <div className="flex w-full justify-center mt-6">
                      <h1 className="flex items-center">
                        Already have an account?
                      </h1>
                      <Link 
                      to="/instructor-login" 
                      className="mx-2 font-[600] text-[#8A70FF] hover:text-[#795cff]">
                        Sign in
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
  );
};

export default InstructorRegister;
