/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import Logo from "../../assets/img/schoolify-black.svg";
import { HiOutlineBars3 } from "react-icons/hi2";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ChevronRightIcon } from "@heroicons/react/solid";

import English from "../../assets/img/US.png";
import Portuguese from "../../assets/img/PT.png";
import Spanish from "../../assets/img/ES.png";
import Chinese from "../../assets/img/CN.png";
import Japanease from "../../assets/img/JP.png";
import Korean from "../../assets/img/KR.png";
import { Link } from "react-router-dom";
import Teacher from "../../assets/img/TeacherAccount.svg"
import TeacherHovered from "../../assets/img/TeacherAccountHovered.svg"
import Student from "../../assets/img/StudentAccount.svg"
import StudentHovered from "../../assets/img/StudentAccountHovered.svg"
import ParentHovered from "../../assets/img/ParentAccountHovered.svg"
import Parent from "../../assets/img/ParentAccount.svg"
import Close from "../../assets/img/close.svg"
import Instructor from "../../assets/img/instructor.svg"
import InstructorHovered from "../../assets/img/instructorHovered.svg"
import Learner from "../../assets/img/learner.svg"
import LearnerHovered from "../../assets/img/learnerHovered.svg"

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openLangMenu, setOpenLangMenu] = useState(false);
  const [showSignInOptions, setShowSignInOptions] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [openOrgLogin, setOpenOrgLogin] = useState(false);
  const [openIndLogin, setOpenIndLogin] = useState(false);
  const [openIndRegister, setOpenIndRegister] = useState(false);
  const [isHoveredStudent, setIsHoveredStudent] = useState(false);
  const [isHoveredTeacher, setIsHoveredTeacher] = useState(false);
  const [isHoveredParent, setIsHoveredParent] = useState(false);
  const [isHoveredLearner, setIsHoveredLearner] = useState(false);
  const [isHoveredInstructor, setIsHoveredInstructor] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const dropdownRef = useRef(null);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleSignInOptions = () => {
    setShowSignInOptions(!showSignInOptions);
  };

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
  const openOrgModal = () => {
    setOpenOrgLogin(true);
  };

    const closeOrgModal = () => {
    setOpenOrgLogin(false);
  };

    const openIndModal = () => {
    setOpenIndLogin(true);
  };


  const closeIndModal = () => {
    setOpenIndLogin(false);
  };

  const openIndRegModal = () => {
    setOpenIndRegister(true)
  }

  const closeIndRegModal = () => {
  setOpenIndRegister(false)
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setOpenLangMenu(false);
    }
  };

  useEffect(() => {
    if ({isOpen, openLangMenu}) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, openLangMenu]);

  const handleOptionClick = (option) => {
    console.log(`Selected option: ${option}`);
    // You can add logic here to handle the selected option
    // For example, redirect to the corresponding page
  };


  const menuOptions = [
    {
      text: "Home",
      id: "#",
    },
    {
      text: "About",
      id: "#aboutUs",
    },
    {
      text: "Services",
      id: "#services",
    },
    {
      text: "News",
    },
    // {
    //   text: "Academy",
    // },
    {
      text: "Contact",
    },
  ];

  const authOptions = [
    {
      text: "Sign In",
    },
    {
      text: "Create Account",
    },
  ];

  const language = [
    {
      value: "English",
      image: English,
    },
    {
      value: "Portuguese",
      image: Portuguese,
    },
    {
      value: "Spanish",
      image: Spanish,
    },
    {
      value: "Chinese",
      image: Chinese,
    },
    {
      value: "Korean",
      image: Korean,
    },
    {
      value: "Japanese",
      image: Japanease,
    }
  ];

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
    setOpenLangMenu(false);
  };
  

  return (
    <div>
      <nav className="navbar">
        <div className="nav-logo-container">
          <img
            src={Logo}
            alt=""
            style={{
              height: "auto",
              width: "160px",
              paddingY: "12px",
              paddingX: "20px",
            }}
          />
        </div>
        <div className="navbar-links-container ">
          {menuOptions.map((option, index) => (
            <a key={index} href={`${option.id}`} className="smooth-scroll mx-4">
              {option.text}
            </a>
          ))}
        </div>

        <div className="flex whitespace-nowrap">
          <div className="navbar-links-container2 gap-2 ">
            <div className="langSel flex">
              <div className="relative flex text-left justify-center items-center">
                <div className="mr-4">
                  <button
                    type="button"
                    className="flex justify-center items-center w-full rounded-md px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 "
                    id="options-menu"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={() => setOpenLangMenu(!openLangMenu)}
                  >
                    <span className="flex items-center">
                      <img
                        src={
                          language.find(
                            (lang) => lang.value === selectedLanguage
                          ).image
                        }
                        alt={selectedLanguage}
                        className="w-6 h-6 rounded-full mr-2 border border-gray-300"
                      />
                      {selectedLanguage}
                    </span>
                    <ChevronDownIcon
                      className="-mr-1 ml-2 h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
                {openLangMenu && (
                  <div
                    ref={dropdownRef}
                    className=" absolute right-0 top-14 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div
                      className="p-4"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      {language.map((lang, index) => (
                        <a
                          key={index}
                          href="#a"
                          className={`flex px-1 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 ${
                            lang.value === selectedLanguage ? "bg-gray-100" : ""
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleLanguageChange(lang.value);
                          }}
                        >
                          <img
                            src={lang.image}
                            alt={lang.value}
                            className="w-5 h-5 rounded-full mr-2 border border-gray-300"
                          />
                          {lang.value}
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="relative inline-block text-left justify-center my-auto mr-4 hidden lg:block">
              {/* <button
        onClick={toggleDropdown}
        type="button"
        className="inline-flex whitespace-nowrap justify-center w-full rounded-md border border-[#7f56d9] shadow-sm bg-white px-4 py-2 text-sm font-bold text-[#7F56D9] hover:bg-gray-50 focus:outline-none "
        id="options-menu"
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        Sign In
        <ChevronDownIcon
                  className="-mr-1 ml-2 h-5 w-5 "
                  aria-hidden="true"
                />
      </button> */}

              <button
                className="inline-flex whitespace-nowrap justify-center w-full rounded-md border border-[#7f56d9] shadow-sm bg-white px-6 py-2 text-sm font-bold text-[#7F56D9] hover:bg-gray-50 focus:outline-none "
                onClick={openOrgModal}
              >
                Sign In
              </button>

              {isOpen && (
                <div
                  ref={dropdownRef}
                  className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <div className="py-1" role="none">
                    <Link
                      // to="/login"
                      className="block p-2 m-2 text-sm text-[#7f56d9] font-bold hover:bg-[#7f56d9] hover:text-white rounded-md"
                      role="menuitem"
                      onClick={openOrgModal}
                    >
                      Organization
                    </Link>

                    <Link
                      // to="/login"
                      className="block p-2 m-2 text-sm text-[#7f56d9] font-bold hover:bg-[#7f56d9] hover:text-white rounded-md"
                      role="menuitem"
                      onClick={openIndModal}
                    >
                      Individual
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <a
              className="flex hidden lg:block px-4 py-2 text-sm text-white border border-gray-300 text-gray-700 hover:bg-[#6E3CDC] hover:text-white rounded-lg bg-[#7f56d9] cursor-pointer"
              onClick={openIndRegModal}
            >
              Create Account
            </a>
          </div>

          <div className="navbar-menu-container flex items-center">
            <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
            {openMenu && (
              <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-end">
                <div className="origin-top-right w-56 bg-white shadow-lg">
                  <button
                    className="absolute top-0 right-0 m-2 p-1 rounded-[20px] bg-gray-200 hover:bg-gray-300 focus:outline-none"
                    onClick={() => setOpenMenu(false)}
                  >
                    <svg
                      className="w-4 h-4 text-gray-600"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 5.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  <div
                    className="py-4"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    {menuOptions.map((option, index) => (
                      <a
                        key={index}
                        href={`${option.id}`}
                        className="block px-4 py-2 m-2 text-sm font-semibold text-[#7f56d9] hover:bg-[#7f56d9] hover:text-white rounded-md"
                        onClick={() => setOpenMenu(false)}
                      >
                        {option.text}
                      </a>
                    ))}
                    <a
                      // onClick={toggleSignInOptions}
                      onClick={openOrgModal}
                      className="block px-4 py-2 m-2 text-sm font-semibold text-[#7f56d9] hover:bg-[#7f56d9] hover:text-white rounded-md cursor-pointer"
                    >
                      <div className="flex w-full justify-between">
                        <h1> Sign in</h1>
                        {/* <ChevronDownIcon
                          className="-mr-1 ml-2 h-5 w-5"
                          aria-hidden="true"
                        /> */}
                      </div>
                    </a>
                    {showSignInOptions && (
                      <>
                        <a
                          onClick={openIndModal}
                          className="block px-8 py-2 m-2 text-sm font-semibold text-[#7f56d9] hover:bg-[#7f56d9] hover:text-white rounded-md"
                        >
                          Individual
                        </a>
                        <a
                          onClick={openOrgModal}
                          className="block px-8 py-2 m-2 text-sm font-semibold text-[#7f56d9] hover:bg-[#7f56d9] hover:text-white rounded-md"
                        >
                          Organization
                        </a>
                      </>
                    )}
                    <a
                      onClick={openIndRegModal}
                      className="block px-4 py-2 m-2 text-sm font-semibold text-[#7f56d9] hover:bg-[#7f56d9] hover:text-white rounded-md"
                    >
                      Create Account
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {openOrgLogin && (
          <div className="fixed inset-0 backdrop-filter backdrop-blur-sm flex items-center justify-center z-50 ">
            <div className="absolute inset-0 bg-gray-900 opacity-75 "></div>
            <div className="bg-white p-8 rounded-[20px] shadow-lg z-10 max-w-[400px] w-full m-8">
              <div className="flex flex-col mb-4">
                <div className="flex justify-between items-center ">
                  <h2 className="text-[24px] text-[#101828] font-black">
                    Login to your Account
                  </h2>
                  <button onClick={closeOrgModal}>
                    <img src={Close} alt="exit" className="h-5 w-5" />
                  </button>
                </div>
                <p>Sign in your organization account</p>
              </div>

              <div className="flex flex-col gap-4">
                <Link
                  to="/student-login"
                  className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
                  onMouseEnter={() => setIsHoveredStudent(true)}
                  onMouseLeave={() => setIsHoveredStudent(false)}
                >
                  <div className="flex justify-center items-center gap-4">
                    <img
                      // src={isHovered ? StudentHovered : Student}
                      src={isHoveredStudent ? StudentHovered : Student}
                      alt="student hat"
                      className="w-8"
                    />
                    <h1
                      className={`font-bold ${
                        isHoveredStudent ? "text-[#7f56d9]" : "text-[#646464]"
                      }`}
                    >
                      I'm a Student
                    </h1>
                  </div>

                  <ChevronRightIcon
                    className={`-mr-1 ml-2 h-5 w-5 ${
                      isHoveredStudent ? "text-[#7f56d9]" : "text-[#646464]"
                    }`}
                    aria-hidden="true"
                  />
                </Link>

                <Link
                  to="/teacher-login"
                  className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
                  onMouseEnter={() => setIsHoveredTeacher(true)}
                  onMouseLeave={() => setIsHoveredTeacher(false)}
                >
                  <div className="flex justify-center items-center gap-4">
                    <img
                      src={isHoveredTeacher ? TeacherHovered : Teacher}
                      alt="student hat"
                      className="w-8"
                    />
                    <h1
                      className={`font-bold ${
                        isHoveredTeacher ? "text-[#7f56d9]" : "text-[#646464]"
                      }`}
                    >
                      I'm a Teacher
                    </h1>
                  </div>

                  <ChevronRightIcon
                    className={`-mr-1 ml-2 h-5 w-5 ${
                      isHoveredTeacher ? "text-[#7f56d9]" : "text-[#646464]"
                    }`}
                    aria-hidden="true"
                  />
                </Link>
                <Link
                  to="/parent-login"
                  className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
                  onMouseEnter={() => setIsHoveredParent(true)}
                  onMouseLeave={() => setIsHoveredParent(false)}
                >
                  <div className="flex justify-center items-center gap-4">
                    <img
                      src={isHoveredParent ? ParentHovered : Parent}
                      alt="student hat"
                      className="w-8"
                    />
                    <h1
                      className={`font-bold ${
                        isHoveredParent ? "text-[#7f56d9]" : "text-[#646464]"
                      }`}
                    >
                      I'm a Parent
                    </h1>
                  </div>

                  <ChevronRightIcon
                    className={`-mr-1 ml-2 h-5 w-5 ${
                      isHoveredParent ? "text-[#7f56d9]" : "text-[#646464]"
                    }`}
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
          </div>
        )}

        {openIndLogin && (
          <div className="fixed inset-0 backdrop-filter backdrop-blur-sm flex items-center justify-center z-50 ">
            <div className="absolute inset-0 bg-gray-900 opacity-75 "></div>
            <div className="bg-white p-8 rounded-[20px] shadow-lg z-10 max-w-[400px] w-full m-8">
              <div className="flex flex-col mb-4">
                <div className="flex justify-between items-center ">
                  <h2 className="text-[24px] text-[#101828] font-black">
                    Login to your Account
                  </h2>
                  <button onClick={closeIndModal}>
                    <img src={Close} alt="exit" className="h-5 w-5" />
                  </button>
                </div>
                <p>Sign in your individual account</p>
              </div>

              <div className="flex flex-col gap-4">
                <Link
                  to="/learner-login"
                  className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
                  onMouseEnter={() => setIsHoveredLearner(true)}
                  onMouseLeave={() => setIsHoveredLearner(false)}
                >
                  <div className="flex justify-center items-center gap-4">
                    <img
                      // src={isHovered ? StudentHovered : Student}
                      src={isHoveredLearner ? LearnerHovered : Learner}
                      alt="student hat"
                      className="w-8"
                    />
                    <h1
                      className={`font-bold ${
                        isHoveredLearner ? "text-[#7f56d9]" : "text-[#646464]"
                      }`}
                    >
                      I'm a Learner
                    </h1>
                  </div>

                  <ChevronRightIcon
                    className={`-mr-1 ml-2 h-5 w-5 ${
                      isHoveredLearner ? "text-[#7f56d9]" : "text-[#646464]"
                    }`}
                    aria-hidden="true"
                  />
                </Link>

                <Link
                  to="/instructor-login"
                  className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
                  onMouseEnter={() => setIsHoveredInstructor(true)}
                  onMouseLeave={() => setIsHoveredInstructor(false)}
                >
                  <div className="flex justify-center items-center gap-4">
                    <img
                      src={isHoveredInstructor ? InstructorHovered : Instructor}
                      alt="student hat"
                      className="w-8"
                    />
                    <h1
                      className={`font-bold ${
                        isHoveredInstructor
                          ? "text-[#7f56d9]"
                          : "text-[#646464]"
                      }`}
                    >
                      I'm a Instructor
                    </h1>
                  </div>

                  <ChevronRightIcon
                    className={`-mr-1 ml-2 h-5 w-5 ${
                      isHoveredInstructor ? "text-[#7f56d9]" : "text-[#646464]"
                    }`}
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
          </div>
        )}

        {openIndRegister && (
          <div className="fixed inset-0 backdrop-filter backdrop-blur-sm flex items-center justify-center z-50 ">
            <div className="absolute inset-0 bg-gray-900 opacity-75 "></div>
            <div className="bg-white p-8 rounded-[20px] shadow-lg z-10 max-w-[400px] w-full m-8">
              <div className="flex flex-col mb-4">
                <div className="flex justify-between items-center ">
                  <h2 className="text-[24px] text-[#101828] font-black">
                    Create Account
                  </h2>
                  <button onClick={closeIndRegModal}>
                    <img src={Close} alt="exit" className="h-5 w-5" />
                  </button>
                </div>
                <p>Sign up an individual account</p>
              </div>

              <div className="flex flex-col gap-4">
                <Link
                  to="/learner-register"
                  className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
                  onMouseEnter={() => setIsHoveredLearner(true)}
                  onMouseLeave={() => setIsHoveredLearner(false)}
                >
                  <div className="flex justify-center items-center gap-4">
                    <img
                      // src={isHovered ? StudentHovered : Student}
                      src={isHoveredLearner ? LearnerHovered : Learner}
                      alt="student hat"
                      className="w-8"
                    />
                    <h1
                      className={`font-bold ${
                        isHoveredLearner ? "text-[#7f56d9]" : "text-[#646464]"
                      }`}
                    >
                      I'm a Learner
                    </h1>
                  </div>

                  <ChevronRightIcon
                    className={`-mr-1 ml-2 h-5 w-5 ${
                      isHoveredLearner ? "text-[#7f56d9]" : "text-[#646464]"
                    }`}
                    aria-hidden="true"
                  />
                </Link>

                <Link
                  to="/instructor-register"
                  className="flex justify-between items-center border rounded-xl py-3 px-4 hover:border-[#7f56d9]"
                  onMouseEnter={() => setIsHoveredInstructor(true)}
                  onMouseLeave={() => setIsHoveredInstructor(false)}
                >
                  <div className="flex justify-center items-center gap-4">
                    <img
                      src={isHoveredInstructor ? InstructorHovered : Instructor}
                      alt="student hat"
                      className="w-8"
                    />
                    <h1
                      className={`font-bold ${
                        isHoveredInstructor
                          ? "text-[#7f56d9]"
                          : "text-[#646464]"
                      }`}
                    >
                      I'm a Instructor
                    </h1>
                  </div>

                  <ChevronRightIcon
                    className={`-mr-1 ml-2 h-5 w-5 ${
                      isHoveredInstructor ? "text-[#7f56d9]" : "text-[#646464]"
                    }`}
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
