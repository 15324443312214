import React from "react";
// import Logo from "../../assets/img/logo-white.png";
import Logo from "../../assets/img/schoolify-white.svg";
import tt from "../../assets/img/tt.png";
import li from "../../assets/img/li.png";
import fb from "../../assets/img/fb.png";
import gh from "../../assets/img/gh.png";
import db from "../../assets/img/db.png";
// import { BsTwitter } from "react-icons/bs";
// import { SiLinkedin } from "react-icons/si";
// import { BsYoutube } from "react-icons/bs";
// import { FaFacebookF } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-bg">
        <div className="flex-container">
          <div className="flex-item-long">
            <div className="footer-logo-container">
              <img src={Logo} alt="" />
            </div>
            <p>
              Top learning experiences that create more talent in the world.
            </p>
          </div>
          
          <div className="grid grid-cols-2 lg:grid-cols-4">
            <div className="flex-item">
              <a href="#a" className="title">Product</a>
              <a href="#a">Overview</a>
              <a href="#a">Features</a>
              <a href="#a">Solutions</a>
              <a href="#a">Tutorials</a>
              <a href="#a">Pricing</a>
            </div>

            <div className="flex-item">
              <a href="#a" className="title">Company</a>
              <a href="#a">About us</a>
              <a href="#a">Careers</a>
              <a href="#a">Press&nbsp;&nbsp; <span className="bg-white rounded-full text-[#101828] text-[12px] font-bold py-1 px-2">New</span></a>
              <a href="#a">News</a>
            </div>

            <div className="flex-item">
              <a  href="#a" className="title">Social</a>
              <a href="#a">Facebook</a>
              <a href="#a">Twitter</a>
              <a href="#a">LinkedIn</a>
              <a href="#a">Instagram</a>
            </div>

            <div className="flex-item">
              <a href="#a" className="title">Legal</a>
              <a href="#a">Terms</a>
              <a href="#a">Privacy</a>
              <a href="#a">Cookies</a>
              <a href="#a">Contact</a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-container justify-center lg:justify-between px-8">
          <div className="text-[#98A2B3] mb-4">
            © 2024 Schoolfy. All rights reserved.
          </div>
          <div className="flex justify-between mb-4 gap-4">
            <img src={tt} alt="" />
            <img src={li} alt="" />
            <img src={fb} alt="" />
            <img src={gh} alt="" />
            <img src={db} alt="" />
          </div>
      </div>
    </div>


  );
};


export default Footer;
