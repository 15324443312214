import React from 'react'
import Image1 from "../../assets/img/abouUs-image1.png";
import Image2 from "../../assets/img/abouUs-image2.png";
import Image3 from "../../assets/img/abouUs-image3.png";
import Image4 from "../../assets/img/abouUs-image4.png";
import Image5 from "../../assets/img/abouUs-image5.png";
import Image6 from "../../assets/img/abouUs-image6.png";
import { animateWithGsap } from "../utils/animations.js";
import { useGSAP } from "@gsap/react";


const AboutUs = () => {
useGSAP(() => {
  animateWithGsap(".aboutUs-section-top", {
    y: 0,
    opacity: 1,
    stagger: 0.2,
  });
});
   

    const aboutUsInfoData = [
      {
        image: Image1,
        title: "Records Management",
        text: "Easily manage and access student records, including grades, attendance, and performance.",

      },
      {
        image: Image2,
        title: "Collaborative Lesson Planning",
        text: "Foster collaboration among educators through interactive lesson planning tools.",

      },
      {
        image: Image3,
        title: "Communication and Announcements",
        text: "Scale your training programs efficiently with our enterprise solutions.",

      },
      {
        image: Image4,
        title: "Grading Tools",
        text: "Effortlessly manage and calculate grades for assignments, exams, and assessments.",

      },
      {
        image: Image5,
        title: "Health Monitoring",
        text: "Our intuitive interface lets you focus on delivering quality content without the hassle.",

      },
      {
        image: Image6,
        title: "Event Management",
        text: "Scale your training programs efficiently with our enterprise solutions.",

      },
    ];

  return (
    <div className="aboutUs-section-wrapper" id="services">
      <div className="aboutUs-section-top">
        <p className="primary-subheading">Our Services</p>
        <h1 className="primary-heading">
          Unlock Your Potential with <span className='text-[#6859FF]'>Schoolfy</span>:<br/>Key Feature
        </h1>
      </div>

      <div className="aboutUs-section-bottom">
        {aboutUsInfoData.map((data) => (
          <div className="aboutUs-section-info px-4" key={data.title}>
            <div className="aboutUs-header-wrapper">
              <div className="aboutUs-boxes-img-container">
                <img src={data.image} alt="about us icon" />
              </div>
              <h2>{data.title}</h2>
            </div>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutUs