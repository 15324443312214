import React from 'react'
import godcentered from '../../assets/img/god-centered.png'
import excellence from '../../assets/img/excellence.png'
import values from '../../assets/img/values.png'
import trust from '../../assets/img/trust.png'

const CoreValue = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="core-values-wrapper">
        <div className="text-center mt-32 mb-20">
          <h1 className="text-[36px] text-[#101828] font-extrabold">
            Core Values
          </h1>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-8 mb-16 mx-4">
          <div className="">
            <div className="flex gap-2 items-center mb-4">
              <img src={godcentered} alt="" />
              <h1 className="text-[24px] text-[#101828] font-extrabold">
                God-Centered
              </h1>
            </div>
            <p className="text-[16px] text-[#646464] leading-7">
              We uphold values of integrity, respect, and compassion in all
              aspects of our work, guided by principles of faith and
              spirituality.
            </p>
          </div>
          <div className="">
            <div className="flex gap-2 items-center mb-4">
              <img src={excellence} alt="" />
              <h1 className="text-[24px] text-[#101828] font-extrabold">
                Excellence
              </h1>
            </div>
            <p className="text-[16px] text-[#646464] leading-7">
              We are committed to delivering excellence in everything we do,
              striving for continuous improvement and innovation.
            </p>
          </div>
          <div className="">
            <div className="flex gap-2 items-center mb-4">
              <img src={values} alt="" />
              <h1 className="text-[24px] text-[#101828] font-extrabold">
                Values
              </h1>
            </div>
            <p className="text-[16px] text-[#646464] leading-7">
              We prioritize honesty, transparency, and accountability, building
              trust with our users and stakeholders.
            </p>
          </div>
          <div className="">
            <div className="flex gap-2 items-center mb-4">
              <img src={trust} alt="" />
              <h1 className="text-[24px] text-[#101828] font-extrabold">
                Trust
              </h1>
            </div>
            <p className="text-[16px] text-[#646464] leading-7">
              We prioritize honesty, transparency, and accountability, building
              trust with our users and stakeholders.
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between gap-4 px-0 lg:px-40">
          <div className="bg-white rounded-lg p-8 shadow-md">
            <h1 className="text-[36px] text-[#101828] font-extrabold mb-4">
              Vision
            </h1>
            <p className="text-[16px] text-[#101828] font-regular">
              Our vision is to become the most trusted brand platform for
              advanced education, accessible to all. We strive to create an
              inclusive learning environment where knowledge is shared and
              education transcends boundaries.
            </p>
          </div>
          <div className="bg-white rounded-lg p-8 shadow-md">
            <h1 className="text-[36px] text-[#101828] font-extrabold mb-4">
              Mission
            </h1>
            <p className="text-[16px] text-[#101828] font-regular">
              Our mission is to offer seamless connectivity to everyone,
              believing that education is a universal right that should be
              accessible to all humanity. We are dedicated to empowering
              learners, educators, and communities through innovative technology
              solutions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoreValue