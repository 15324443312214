import bg from "../assets/img/bg.png";
import logo2 from "../../src/assets/img/Schoolfy-white.png";
import logo3 from "../../src/assets/img/Schoolfy-black.png";
import axios from "axios";
import toast from "react-hot-toast";
const ForgotPassword = () => {
  
    const handleFormSubmit = async(event) => {
      event.preventDefault();
      const enteredEmail = event.target.elements.email.value;
      try {
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/student/forgotpassword`, {enteredEmail});
        toast.success("Link has been sent to your email");
        console.log(response)
        window.location.reload()
      } catch (error) {
        toast.error("Reset password failed")
      }
    };
  return (

        <div className="flex justify-start items-center h-screen overflow-hidden">
          <div
            className="w-full justify-center items-center max-w-screen-lg h-full bg-cover bg-center hidden lg:flex lg:justify-center lg:items-center"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url(${bg})`,
            }}
          >
            <img
              src={logo2}
              className="relative"
              alt="Logo"
              style={{
                height: "auto",
                width: "55%",
                paddingY: "12px",
                paddingX: "20px",
              }}
            />
          </div>

          <div className="w-full max-w-screen-lg flex justify-center items-center ">
            <div className="w-full max-w-md m-16 flex flex-col h-screen items-center lg:justify-center justify-start">
              <div className="flex-col lg:hidden flex justify-center py-4 m-6">
                <img src={logo3} alt="Icon" className="w-48" />
              </div>

              <form onSubmit={handleFormSubmit} className="w-full py-4 m-6">
                <div className="mb-8 text-center">
                  <h2 className="text-3xl font-bold m-2 text-start">
                    Forgot Password?
                  </h2>
                  <p className="text-lg font-[500]  m-2 text-start">
                    Enter the email address associated with your account.
                  </p>
                </div>

                <div className="mb-6">
                  <div className="relative">
                    <input
                      type="text"
                      name="email"
                      className="w-full py-4 px-6 border border-gray-300 rounded-full focus:outline-none focus:border-[#8A70FF]"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full py-4 rounded-full bg-[#8A70FF] text-white font-medium hover:bg-[#795cff] transition duration-300">
                  Send
                </button>
                <div className="flex w-full justify-center mt-6">
                  <a
                    href="/login"
                    className="mx-2 font-[600] text-[#8A70FF] hover:text-[#795cff]"
                  >
                    Back to Login
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
  );
};

export default ForgotPassword;
