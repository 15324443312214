import React from 'react'
import globe from '../../assets/img/globe.json'
import Lottie from "lottie-react";

const Experts = () => {

    return (
      <div>
          <div className="relative bg-hidden lg:bg-[#F4EBFF] rounded-3xl p-2 lg:p-12 mt-40 flex flex-col lg:flex-row gap-12">
            <div className="relative lg:absolute -top-14 left-0 lg:left-8 w-full lg:w-[30%]">
                <Lottie animationData={globe} />
            </div>
            <div className="w-full z-10 right-0 ml-0 lg:ml-96 px-4 lg:px-12">
                <h1 className='text-[36px] text-[#101828] font-bold mb-4'>Our Experts </h1>
                <p className='text-[16px] text-[#646464]'>At Schoolfy, we believe that the heart of exceptional learning lies in the expertise and passion of our educators. "Our Experts" brings together a diverse tapestry of dedicated professionals from across the globe, each bringing their unique perspectives, experiences, and teaching styles to our platform. Our teachers are selected for their deep subject matter knowledge and their proven ability to inspire and engage students.</p>
            </div>
          </div>
      </div>
    )
}

export default Experts