import "./App.css";
import Home from "../Components/Home";
import AboutUs from "../Components/AboutUs";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";
import Blogs from "../Components/Blogs";
import CoreValue from "../Components/CoreValue";
import ServiceFeature from "../Components/ServiceFeature";
import Experts from "../Components/Experts";

function App() {
  return (
    <>
      <div className="App">
        <Home />
        <CoreValue />
        <AboutUs />
        <Experts />
        <Contact />
        <Blogs />
        <Footer />
      </div>
    </>
  );
}

export default App;
